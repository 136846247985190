import { StrictMode, Suspense } from 'react';

import * as ReactDOM from 'react-dom/client';

import { FixedSpinner } from '@proptly/ui';

import App from './app';

const enableMocking = async () => {
  // This if statement is used to enable the mock service worker only in development and when the environment variable is set to true
  // This is necessary to avoid the mock service worker in production bundle
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.NX_PUBLIC_FRONTEND_MOCK_SERVICE_WORKER_ENABLE === 'true'
  ) {
    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return (await import('./api/mocks/browser')).worker.start({
      // I only want to intercept requests from the API
      onUnhandledRequest(req, print) {
        if (!req.url.includes('/api/')) {
          return;
        }
        print.warning();
      },
    });
  }

  return;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

enableMocking().then(() => {
  root.render(
    <StrictMode>
      <Suspense fallback={<FixedSpinner />}>
        <App />
      </Suspense>
    </StrictMode>,
  );
});
