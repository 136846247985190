export const MESSAGES_REFETCH_INTERVAL = 10_000; // 10 seconds
export const NOTIFICATIONS_REFETCH_INTERVAL = 10_000; // 10 seconds
export const COUNTERS_DEBOUNCE_TIME = 5_000; // 5 seconds
export const DRAWER_CLOSE_DELAY = 300; // 300 ms

export const CONTRACTOR_API_URL = process.env
  .NX_PUBLIC_CONTRACTOR_API_URL as string;
export const CONTRACTOR_EMPLOYEE_APP_URL = process.env
  .NX_PUBLIC_CONTRACTOR_EMPLOYEE_APP_URL as string;
export const CONTRACTOR_CLIENT_APP_URL = process.env
  .NX_PUBLIC_CONTRACTOR_CLIENT_APP_URL as string;
export const CONTRACTOR_SDK_URL = process.env
  .NX_PUBLIC_CONTRACTOR_SDK_URL as string;

export const ROUTING_API_URL = process.env.NX_PUBLIC_ROUTING_API_URL as string;
export const ROUTING_APP_URL = process.env.NX_PUBLIC_ROUTING_APP_URL as string;

export const AUTH_API_URL = process.env.NX_PUBLIC_AUTH_API_URL as string;
export const AUTH_APP_URL = process.env.NX_PUBLIC_AUTH_APP_URL as string;

export const ADMIN_APP_URL = process.env.NX_PUBLIC_ADMIN_APP_URL as string;

export const TIMELINE_API_URL = process.env
  .NX_PUBLIC_TIMELINE_API_URL as string;

export const CHAT_API_URL = process.env.NX_PUBLIC_CHAT_API_URL as string;

export const FILE_API_URL = process.env.NX_PUBLIC_FILE_API_URL as string;

export const APP_DOMAIN = process.env.NX_PUBLIC_DOMAIN as string;
