import { lazy } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { IconContext } from '@phosphor-icons/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import GTM from 'react-gtm-module';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { initI18n, useHtmlLangUpdater } from '@proptly/locale';
import propsOverride from '@proptly/props-override';
import {
  ConfirmProvider,
  ErrorBoundary,
  FilesPreviewProvider,
  initSentryReact,
  queryClient,
  theme,
  ThrowNotFound,
  useClickTracking,
} from '@proptly/ui';

import {
  ExpiredLinkFallback,
  NavigateToProjectChatPage,
  NotFoundFallback,
} from './components';
initSentryReact('contractor-client-app');

propsOverride();

const ProjectFeedbackPage = lazy(
  () => import('./screens/project-page/project-feedback-page'),
);
const ProjectCustomerPortalPage = lazy(
  () => import('./screens/project-page/project-customer-portal-page'),
);
const ProjectRatingPage = lazy(
  () => import('./screens/project-page/project-rating-page'),
);
const OrderCustomerPortalPage = lazy(
  () => import('./screens/order-page/order-customer-portal-page'),
);
const OrderCustomerPortalChatPage = lazy(
  () => import('./screens/order-page/order-customer-portal-chat-page'),
);

const gtmId = process.env.NX_PUBLIC_CONTRACTOR_GTM_ID;

let isInitialised = false;
let initPromise: Promise<void>;

function useInitializer() {
  if (isInitialised) {
    return;
  }
  if (initPromise) {
    throw initPromise;
  }
  if (gtmId) {
    GTM.initialize({ gtmId });
  }

  initPromise = initI18n({
    cacheLang: false,
    order: ['querystring', 'navigator'],
  }).then(() => {
    isInitialised = true;
  });

  // throwing promise triggers suspense
  throw initPromise;
}

export function App() {
  useClickTracking();
  useInitializer();
  useHtmlLangUpdater();

  return (
    <ErrorBoundary inContainer>
      <ErrorBoundary FallbackComponent={NotFoundFallback}>
        <ErrorBoundary FallbackComponent={ExpiredLinkFallback}>
          <Routes>
            <Route path="feedback" element={<ProjectFeedbackPage />} />
            <Route path="order/:token" element={<OrderCustomerPortalPage />}>
              <Route
                path="chat/:chatId/messages"
                element={<OrderCustomerPortalChatPage />}
              />
            </Route>
            <Route path="project/:token">
              <Route index element={<NavigateToProjectChatPage />} />
              <Route
                path="chat/:chatId/messages"
                element={<ProjectCustomerPortalPage />}
              />
            </Route>
            <Route
              path="project/:token/rating"
              element={<ProjectRatingPage />}
            />
            <Route path="*" element={<ThrowNotFound />} />
          </Routes>
        </ErrorBoundary>
      </ErrorBoundary>
    </ErrorBoundary>
  );
}

export default () => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <ConfirmProvider>
          <FilesPreviewProvider>
            <IconContext.Provider
              value={{
                size: 24,
              }}
            >
              <App />
            </IconContext.Provider>
          </FilesPreviewProvider>
        </ConfirmProvider>
      </BrowserRouter>
    </ChakraProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
